import React from "react";

import "./index.scss";

const RealisationCard = ({ cardName, cardImage, cardDescription, techTag }) => {
	const displayTags = techTag.split(",").map((tag) => (
		<span key={tag} className="tech-tag">
			{tag}
		</span>
	));

	return (
		<div className="item-card">
			<img
				src={require(`../../images/realisation-cards-image/${cardImage}.webp`).default}
				alt="Someone drawing"
				className="item-card-image"
			/>
			<div className="item-text">
				<p className="item-card-name">{cardName}</p>
				<p className="item-card-description">{cardDescription}</p>
			</div>
			<div className="tech-tag-list">{displayTags}</div>
		</div>
	);
};

export default RealisationCard;
