import React, { useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import "./index.scss";
import BtnContact from "../btnContact";
import HeaderContext from "../../context/headerContext";
import Logo from "../../images/logo-cli.svg";
import RobotFace from "../../images/robot-face.svg";

const Header = () => {
	const navbarContext = useContext(HeaderContext);
	const { ref, inView } = useInView({
		threshold: 0.05,
	});

	const handleVisibility = (x) => {
		navbarContext.isHeaderHiddenFct(x);
	};

	useEffect(() => {
		handleVisibility(inView);
	}, [inView]);

	return (
		<header ref={ref}>
			<div className="hero">
				<div className="hero-container">
					<img src={Logo} alt="" className="hero-logo" />
					<h1>Coopérative Libre Informatique</h1>
					<div className="hero-box">
						<div className="hero-text">
							<p className="hero-paragraph">Travailler ensemble autrement.</p>
						</div>
						<BtnContact>Écrivez-nous !</BtnContact>
					</div>
				</div>
				<div className="robot-face-container">
					<img
						src={RobotFace}
						alt="An androgynous face looking like a robot, with bright eyes and a wide smile."
					/>
				</div>
			</div>
		</header>
	);
};

export default Header;
