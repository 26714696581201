import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout/index";
import Seo from "../components/seo";
import RealisationCard from "../components/realisationCard";
import ContactForm from "../components/contactForm";
import "../scss/index.scss";
import Header from "../components/header";
import Arrow from "../images/arrow.svg";

const IndexPage = () => {
	return (
		<Layout>
			<Seo title="Association de travailleuses et travailleurs du numérique" />
			<Header />

			<div className="page-container">
				<section className="realisation index-section">
					<div className="realisation-bg-title">
						<svg viewBox="0 0 945 224" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M174.95 160.8C168.85 160.8 163.4 162 158.6 164.4C153.8 166.7 150.35 170.05 148.25 174.45V222H128.15V143.4H146.6V160.2C149.3 154.8 152.8 150.55 157.1 147.45C161.5 144.35 166.1 142.65 170.9 142.35C173 142.35 174.35 142.4 174.95 142.5V160.8Z"
								fill="#A6D0D9"
							/>
							<path
								d="M221.582 223.5C213.082 223.5 205.732 221.65 199.532 217.95C193.432 214.25 188.682 209.35 185.282 203.25C181.882 197.05 180.182 190.35 180.182 183.15C180.182 175.75 181.832 168.95 185.132 162.75C188.532 156.45 193.332 151.45 199.532 147.75C205.732 143.95 213.132 142.05 221.732 142.05C230.332 142.05 237.682 143.95 243.782 147.75C249.882 151.45 254.532 156.4 257.732 162.6C261.032 168.7 262.682 175.3 262.682 182.4C262.682 185 262.532 187.25 262.232 189.15H201.482C201.982 195.25 204.232 200.05 208.232 203.55C212.332 207.05 217.032 208.8 222.332 208.8C226.332 208.8 230.082 207.85 233.582 205.95C237.182 203.95 239.632 201.35 240.932 198.15L258.182 202.95C255.282 208.95 250.632 213.9 244.232 217.8C237.832 221.6 230.282 223.5 221.582 223.5ZM201.032 176.4H242.132C241.532 170.5 239.282 165.75 235.382 162.15C231.582 158.45 226.932 156.6 221.432 156.6C216.032 156.6 211.382 158.45 207.482 162.15C203.682 165.75 201.532 170.5 201.032 176.4ZM228.032 134.25L215.132 130.35L223.232 112.5H241.982L228.032 134.25Z"
								fill="#A6D0D9"
							/>
							<path
								d="M269.527 198.75C269.527 193.65 270.927 189.2 273.727 185.4C276.627 181.5 280.627 178.5 285.727 176.4C290.827 174.3 296.677 173.25 303.277 173.25C306.577 173.25 309.927 173.55 313.327 174.15C316.727 174.65 319.677 175.4 322.177 176.4V172.2C322.177 167.2 320.677 163.35 317.677 160.65C314.777 157.95 310.427 156.6 304.627 156.6C300.327 156.6 296.227 157.35 292.327 158.85C288.427 160.35 284.327 162.55 280.027 165.45L273.577 152.25C278.777 148.85 284.077 146.3 289.477 144.6C294.977 142.9 300.727 142.05 306.727 142.05C317.927 142.05 326.627 144.85 332.827 150.45C339.127 156.05 342.277 164.05 342.277 174.45V199.5C342.277 201.7 342.627 203.25 343.327 204.15C344.027 204.95 345.277 205.45 347.077 205.65V222C345.277 222.4 343.627 222.65 342.127 222.75C340.727 222.95 339.527 223.05 338.527 223.05C334.527 223.05 331.477 222.15 329.377 220.35C327.277 218.55 326.027 216.35 325.627 213.75L325.177 209.7C321.677 214.2 317.377 217.65 312.277 220.05C307.277 222.35 302.127 223.5 296.827 223.5C291.527 223.5 286.827 222.45 282.727 220.35C278.627 218.15 275.377 215.2 272.977 211.5C270.677 207.7 269.527 203.45 269.527 198.75ZM318.427 202.8C320.927 200.4 322.177 198.1 322.177 195.9V187.95C316.977 185.85 311.727 184.8 306.427 184.8C301.127 184.8 296.827 185.95 293.527 188.25C290.227 190.45 288.577 193.4 288.577 197.1C288.577 200.1 289.777 202.8 292.177 205.2C294.577 207.6 298.027 208.8 302.527 208.8C305.527 208.8 308.427 208.25 311.227 207.15C314.127 206.05 316.527 204.6 318.427 202.8Z"
								fill="#A6D0D9"
							/>
							<path
								d="M360.91 112.5H381.01V196.8C381.01 200.5 381.61 202.95 382.81 204.15C384.01 205.35 385.66 205.95 387.76 205.95C389.36 205.95 391.01 205.75 392.71 205.35C394.41 204.95 395.91 204.45 397.21 203.85L399.91 219.15C397.21 220.45 394.06 221.45 390.46 222.15C386.86 222.85 383.61 223.2 380.71 223.2C374.41 223.2 369.51 221.55 366.01 218.25C362.61 214.85 360.91 210.05 360.91 203.85V112.5Z"
								fill="#A6D0D9"
							/>
							<path
								d="M408.667 132.45V112.5H428.767V132.45H408.667ZM408.667 222V143.4H428.767V222H408.667Z"
								fill="#A6D0D9"
							/>
							<path
								d="M475.85 223.5C469.15 223.5 462.75 222.4 456.65 220.2C450.55 218 445.3 214.75 440.9 210.45L448.1 197.4C452.8 201.2 457.4 204 461.9 205.8C466.4 207.6 470.8 208.5 475.1 208.5C479 208.5 482.1 207.8 484.4 206.4C486.8 205 488 202.9 488 200.1C488 197.2 486.55 195.25 483.65 194.25C480.75 193.15 476.7 191.8 471.5 190.2C465.7 188.4 460.85 186.55 456.95 184.65C453.05 182.75 450.1 180.45 448.1 177.75C446.2 175.05 445.25 171.55 445.25 167.25C445.25 159.55 448.15 153.4 453.95 148.8C459.75 144.2 467.45 141.9 477.05 141.9C482.65 141.9 487.95 142.8 492.95 144.6C497.95 146.3 502.5 149.1 506.6 153L498.5 165.75C490.8 159.75 483.35 156.75 476.15 156.75C472.95 156.75 470.1 157.35 467.6 158.55C465.2 159.75 464 161.85 464 164.85C464 167.65 465.15 169.65 467.45 170.85C469.85 172.05 473.45 173.35 478.25 174.75C484.45 176.65 489.7 178.5 494 180.3C498.4 182.1 501.7 184.4 503.9 187.2C506.2 190 507.35 193.8 507.35 198.6C507.35 206.4 504.5 212.5 498.8 216.9C493.1 221.3 485.45 223.5 475.85 223.5Z"
								fill="#A6D0D9"
							/>
							<path
								d="M515.621 198.75C515.621 193.65 517.021 189.2 519.821 185.4C522.721 181.5 526.721 178.5 531.821 176.4C536.921 174.3 542.771 173.25 549.371 173.25C552.671 173.25 556.021 173.55 559.421 174.15C562.821 174.65 565.771 175.4 568.271 176.4V172.2C568.271 167.2 566.771 163.35 563.771 160.65C560.871 157.95 556.521 156.6 550.721 156.6C546.421 156.6 542.321 157.35 538.421 158.85C534.521 160.35 530.421 162.55 526.121 165.45L519.671 152.25C524.871 148.85 530.171 146.3 535.571 144.6C541.071 142.9 546.821 142.05 552.821 142.05C564.021 142.05 572.721 144.85 578.921 150.45C585.221 156.05 588.371 164.05 588.371 174.45V199.5C588.371 201.7 588.721 203.25 589.421 204.15C590.121 204.95 591.371 205.45 593.171 205.65V222C591.371 222.4 589.721 222.65 588.221 222.75C586.821 222.95 585.621 223.05 584.621 223.05C580.621 223.05 577.571 222.15 575.471 220.35C573.371 218.55 572.121 216.35 571.721 213.75L571.271 209.7C567.771 214.2 563.471 217.65 558.371 220.05C553.371 222.35 548.221 223.5 542.921 223.5C537.621 223.5 532.921 222.45 528.821 220.35C524.721 218.15 521.471 215.2 519.071 211.5C516.771 207.7 515.621 203.45 515.621 198.75ZM564.521 202.8C567.021 200.4 568.271 198.1 568.271 195.9V187.95C563.071 185.85 557.821 184.8 552.521 184.8C547.221 184.8 542.921 185.95 539.621 188.25C536.321 190.45 534.671 193.4 534.671 197.1C534.671 200.1 535.871 202.8 538.271 205.2C540.671 207.6 544.121 208.8 548.621 208.8C551.621 208.8 554.521 208.25 557.321 207.15C560.221 206.05 562.621 204.6 564.521 202.8Z"
								fill="#A6D0D9"
							/>
							<path
								d="M653.521 217.95C650.821 219.15 647.471 220.35 643.471 221.55C639.571 222.75 635.521 223.35 631.321 223.35C627.621 223.35 624.221 222.7 621.121 221.4C618.021 220.1 615.521 218.05 613.621 215.25C611.821 212.35 610.921 208.6 610.921 204V158.85H600.571V143.4H610.921V117.9H631.021V143.4H647.521V158.85H631.021V197.25C631.021 199.95 631.721 201.9 633.121 203.1C634.621 204.3 636.421 204.9 638.521 204.9C640.621 204.9 642.671 204.55 644.671 203.85C646.671 203.15 648.271 202.55 649.471 202.05L653.521 217.95Z"
								fill="#A6D0D9"
							/>
							<path
								d="M663.99 132.45V112.5H684.09V132.45H663.99ZM663.99 222V143.4H684.09V222H663.99Z"
								fill="#A6D0D9"
							/>
							<path
								d="M738.522 223.5C729.922 223.5 722.522 221.65 716.322 217.95C710.222 214.15 705.522 209.15 702.222 202.95C698.922 196.75 697.272 190.05 697.272 182.85C697.272 175.55 698.922 168.8 702.222 162.6C705.622 156.4 710.372 151.45 716.472 147.75C722.672 143.95 730.022 142.05 738.522 142.05C747.022 142.05 754.322 143.95 760.422 147.75C766.622 151.45 771.372 156.4 774.672 162.6C777.972 168.8 779.622 175.55 779.622 182.85C779.622 190.05 777.972 196.75 774.672 202.95C771.372 209.15 766.622 214.15 760.422 217.95C754.322 221.65 747.022 223.5 738.522 223.5ZM717.972 182.85C717.972 187.45 718.872 191.55 720.672 195.15C722.472 198.65 724.922 201.4 728.022 203.4C731.122 205.4 734.622 206.4 738.522 206.4C742.322 206.4 745.772 205.4 748.872 203.4C751.972 201.3 754.422 198.5 756.222 195C758.122 191.4 759.072 187.3 759.072 182.7C759.072 178.2 758.122 174.2 756.222 170.7C754.422 167.1 751.972 164.3 748.872 162.3C745.772 160.2 742.322 159.15 738.522 159.15C734.622 159.15 731.122 160.2 728.022 162.3C724.922 164.3 722.472 167.1 720.672 170.7C718.872 174.2 717.972 178.25 717.972 182.85Z"
								fill="#A6D0D9"
							/>
							<path
								d="M866.239 222H846.139V177.9C846.139 171.6 845.039 167 842.839 164.1C840.639 161.2 837.589 159.75 833.689 159.75C830.989 159.75 828.239 160.45 825.439 161.85C822.739 163.25 820.289 165.2 818.089 167.7C815.889 170.1 814.289 172.9 813.289 176.1V222H793.189V143.4H811.339V157.95C814.239 153.05 818.439 149.2 823.939 146.4C829.439 143.5 835.639 142.05 842.539 142.05C847.439 142.05 851.439 142.95 854.539 144.75C857.639 146.45 860.039 148.8 861.739 151.8C863.439 154.7 864.589 158 865.189 161.7C865.889 165.4 866.239 169.15 866.239 172.95V222Z"
								fill="#A6D0D9"
							/>
							<path
								d="M912.666 223.5C905.966 223.5 899.566 222.4 893.466 220.2C887.366 218 882.116 214.75 877.716 210.45L884.916 197.4C889.616 201.2 894.216 204 898.716 205.8C903.216 207.6 907.616 208.5 911.916 208.5C915.816 208.5 918.916 207.8 921.216 206.4C923.616 205 924.816 202.9 924.816 200.1C924.816 197.2 923.366 195.25 920.466 194.25C917.566 193.15 913.516 191.8 908.316 190.2C902.516 188.4 897.666 186.55 893.766 184.65C889.866 182.75 886.916 180.45 884.916 177.75C883.016 175.05 882.066 171.55 882.066 167.25C882.066 159.55 884.966 153.4 890.766 148.8C896.566 144.2 904.266 141.9 913.866 141.9C919.466 141.9 924.766 142.8 929.766 144.6C934.766 146.3 939.316 149.1 943.416 153L935.316 165.75C927.616 159.75 920.166 156.75 912.966 156.75C909.766 156.75 906.916 157.35 904.416 158.55C902.016 159.75 900.816 161.85 900.816 164.85C900.816 167.65 901.966 169.65 904.266 170.85C906.666 172.05 910.266 173.35 915.066 174.75C921.266 176.65 926.516 178.5 930.816 180.3C935.216 182.1 938.516 184.4 940.716 187.2C943.016 190 944.166 193.8 944.166 198.6C944.166 206.4 941.316 212.5 935.616 216.9C929.916 221.3 922.266 223.5 912.666 223.5Z"
								fill="#A6D0D9"
							/>
							<path
								d="M73.1999 80.9999H53.0999V36.8999C53.0999 30.5999 51.9999 25.9999 49.7999 23.0999C47.5999 20.1999 44.5499 18.7499 40.6499 18.7499C37.9499 18.7499 35.1999 19.4499 32.3999 20.8499C29.6999 22.2499 27.2499 24.1999 25.0499 26.6999C22.8499 29.0999 21.2499 31.8999 20.2499 35.0999V80.9999H0.149902V2.3999H18.2999V16.9499C21.1999 12.0499 25.3999 8.19991 30.8999 5.39991C36.3999 2.4999 42.5999 1.0499 49.4999 1.0499C54.3999 1.0499 58.3999 1.9499 61.4999 3.7499C64.5999 5.4499 66.9999 7.7999 68.6999 10.7999C70.3999 13.6999 71.5499 16.9999 72.1499 20.6999C72.8499 24.3999 73.1999 28.1499 73.1999 31.9499V80.9999Z"
								fill="#A6D0D9"
							/>
							<path
								d="M126.977 82.4999C118.377 82.4999 110.977 80.6499 104.777 76.9499C98.6772 73.1499 93.9772 68.1499 90.6772 61.9499C87.3772 55.7499 85.7272 49.0499 85.7272 41.8499C85.7272 34.5499 87.3772 27.7999 90.6772 21.5999C94.0772 15.3999 98.8272 10.4499 104.927 6.7499C111.127 2.9499 118.477 1.0499 126.977 1.0499C135.477 1.0499 142.777 2.9499 148.877 6.7499C155.077 10.4499 159.827 15.3999 163.127 21.5999C166.427 27.7999 168.077 34.5499 168.077 41.8499C168.077 49.0499 166.427 55.7499 163.127 61.9499C159.827 68.1499 155.077 73.1499 148.877 76.9499C142.777 80.6499 135.477 82.4999 126.977 82.4999ZM106.427 41.8499C106.427 46.4499 107.327 50.5499 109.127 54.1499C110.927 57.6499 113.377 60.3999 116.477 62.3999C119.577 64.3999 123.077 65.3999 126.977 65.3999C130.777 65.3999 134.227 64.3999 137.327 62.3999C140.427 60.2999 142.877 57.4999 144.677 53.9999C146.577 50.3999 147.527 46.2999 147.527 41.6999C147.527 37.1999 146.577 33.1999 144.677 29.6999C142.877 26.0999 140.427 23.2999 137.327 21.2999C134.227 19.1999 130.777 18.1499 126.977 18.1499C123.077 18.1499 119.577 19.1999 116.477 21.2999C113.377 23.2999 110.927 26.0999 109.127 29.6999C107.327 33.1999 106.427 37.2499 106.427 41.8499Z"
								fill="#A6D0D9"
							/>
							<path
								d="M210.594 82.4999C203.894 82.4999 197.494 81.3999 191.394 79.1999C185.294 76.9999 180.044 73.7499 175.644 69.4499L182.844 56.3999C187.544 60.1999 192.144 62.9999 196.644 64.7999C201.144 66.5999 205.544 67.4999 209.844 67.4999C213.744 67.4999 216.844 66.7999 219.144 65.3999C221.544 63.9999 222.744 61.8999 222.744 59.0999C222.744 56.1999 221.294 54.2499 218.394 53.2499C215.494 52.1499 211.444 50.7999 206.244 49.1999C200.444 47.3999 195.594 45.5499 191.694 43.6499C187.794 41.7499 184.844 39.4499 182.844 36.7499C180.944 34.0499 179.994 30.5499 179.994 26.2499C179.994 18.5499 182.894 12.3999 188.694 7.7999C194.494 3.1999 202.194 0.899902 211.794 0.899902C217.394 0.899902 222.694 1.7999 227.694 3.5999C232.694 5.29991 237.244 8.09991 241.344 11.9999L233.244 24.7499C225.544 18.7499 218.094 15.7499 210.894 15.7499C207.694 15.7499 204.844 16.3499 202.344 17.5499C199.944 18.7499 198.744 20.8499 198.744 23.8499C198.744 26.6499 199.894 28.6499 202.194 29.8499C204.594 31.0499 208.194 32.3499 212.994 33.7499C219.194 35.6499 224.444 37.4999 228.744 39.2999C233.144 41.0999 236.444 43.3999 238.644 46.1999C240.944 48.9999 242.094 52.7999 242.094 57.5999C242.094 65.3999 239.244 71.4999 233.544 75.8999C227.844 80.2999 220.194 82.4999 210.594 82.4999Z"
								fill="#A6D0D9"
							/>
						</svg>
					</div>
					<div className="realisation-card-group">
						<RealisationCard
							cardName="pyHeatPump"
							cardImage="pyheatpump"
							cardDescription="Pilote de pompe à chaleur à distance. Projet de remplacement de RPiPasserelle d'Otec."
							techTag="python, js, mysql"
						/>
						<RealisationCard
							cardName="Chronobriq"
							cardImage="chronobriq"
							cardDescription="Interface de comptabilité horaire."
							techTag="ror, react, pgsql"
						/>
						<RealisationCard
							cardName="www.cli.coop"
							cardImage="cli-web"
							cardDescription="Site web de notre coopérative."
							techTag="gatsby, react"
						/>
					</div>
				</section>
				<section className="index-section redirect">
					<div className="redirect-container">
						<p className="redirect-text-left">Du développement web et logiciel ...</p>
						<img src={Arrow} className="redirect-arrow-left" alt="arrow" />
						<Link to="/services" className="redirect-link">
							<button className="btn">Consultez nos services !</button>
						</Link>{" "}
						<img src={Arrow} className="redirect-arrow-right" alt="arrow" />
						<p className="redirect-text-right">Des formations, de la maintenance ...</p>
					</div>
					<div className="redirect-container">
						<p className="redirect-text-left">
							Une production numérique solidaire, émancipée et émancipatrice ...
						</p>
						<img src={Arrow} className="redirect-arrow-left" alt="arrow" />
						<Link to="/a-propos" className="redirect-link">
							<button className="btn">
								Découvrez notre manière de fonctionner !
							</button>
						</Link>{" "}
						<img src={Arrow} className="redirect-arrow-right" alt="arrow" />
						<p className="redirect-text-right">
							De l'accompagnement et de la sécurité (par défaut !) ...
						</p>
					</div>
				</section>
				<section className="contact index-section" id="contact">
					<div className="contact-bg-title">
						<svg viewBox="0 0 364 108" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M23 45.272C21.848 45.784 20.4186 46.296 18.712 46.808C17.048 47.32 15.32 47.576 13.528 47.576C11.9493 47.576 10.4986 47.2987 9.17596 46.744C7.85329 46.1894 6.78663 45.3147 5.97596 44.12C5.20796 42.8827 4.82396 41.2827 4.82396 39.32V20.056H0.407959V13.464H4.82396V2.58403H13.4V13.464H20.44V20.056H13.4V36.44C13.4 37.592 13.6986 38.424 14.296 38.936C14.936 39.448 15.704 39.704 16.6 39.704C17.496 39.704 18.3706 39.5547 19.224 39.256C20.0773 38.9574 20.76 38.7014 21.272 38.488L23 45.272Z"
								fill="#A6D0D9"
							/>
							<path
								d="M47.4345 20.888C44.8318 20.888 42.5065 21.4 40.4585 22.424C38.4105 23.4054 36.9385 24.8347 36.0425 26.712V47H27.4665V13.464H35.3385V20.632C36.4905 18.328 37.9838 16.5147 39.8185 15.192C41.6958 13.8694 43.6585 13.144 45.7065 13.016C46.6025 13.016 47.1785 13.0374 47.4345 13.08V20.888Z"
								fill="#A6D0D9"
							/>
							<path
								d="M49.725 37.08C49.725 34.904 50.3223 33.0054 51.517 31.384C52.7543 29.72 54.461 28.44 56.637 27.544C58.813 26.648 61.309 26.2 64.125 26.2C65.533 26.2 66.9623 26.328 68.413 26.584C69.8636 26.7974 71.1223 27.1174 72.189 27.544V25.752C72.189 23.6187 71.549 21.976 70.269 20.824C69.0316 19.672 67.1756 19.096 64.701 19.096C62.8663 19.096 61.117 19.416 59.453 20.056C57.789 20.696 56.0396 21.6347 54.205 22.872L51.453 17.24C53.6716 15.7894 55.933 14.7014 58.237 13.976C60.5836 13.2507 63.037 12.888 65.597 12.888C70.3756 12.888 74.0876 14.0827 76.733 16.472C79.421 18.8614 80.765 22.2747 80.765 26.712V37.4C80.765 38.3387 80.9143 39 81.213 39.384C81.5116 39.7254 82.045 39.9387 82.813 40.024V47C82.045 47.1707 81.341 47.2774 80.701 47.32C80.1036 47.4054 79.5916 47.448 79.165 47.448C77.4583 47.448 76.157 47.064 75.261 46.296C74.365 45.528 73.8316 44.5894 73.661 43.48L73.469 41.752C71.9756 43.672 70.141 45.144 67.965 46.168C65.8316 47.1494 63.6343 47.64 61.373 47.64C59.1116 47.64 57.1063 47.192 55.357 46.296C53.6076 45.3574 52.221 44.0987 51.197 42.52C50.2156 40.8987 49.725 39.0854 49.725 37.08ZM70.589 38.808C71.6556 37.784 72.189 36.8027 72.189 35.864V32.472C69.9703 31.576 67.7303 31.128 65.469 31.128C63.2076 31.128 61.373 31.6187 59.965 32.6C58.557 33.5387 57.853 34.7974 57.853 36.376C57.853 37.656 58.365 38.808 59.389 39.832C60.413 40.856 61.885 41.368 63.805 41.368C65.085 41.368 66.3223 41.1334 67.517 40.664C68.7543 40.1947 69.7783 39.576 70.589 38.808Z"
								fill="#A6D0D9"
							/>
							<path
								d="M96.6155 47L84.2635 13.464H93.1595L101.607 39.192L110.119 13.464H118.247L105.959 47H96.6155Z"
								fill="#A6D0D9"
							/>
							<path
								d="M119.6 37.08C119.6 34.904 120.197 33.0054 121.392 31.384C122.629 29.72 124.336 28.44 126.512 27.544C128.688 26.648 131.184 26.2 134 26.2C135.408 26.2 136.837 26.328 138.288 26.584C139.739 26.7974 140.997 27.1174 142.064 27.544V25.752C142.064 23.6187 141.424 21.976 140.144 20.824C138.907 19.672 137.051 19.096 134.576 19.096C132.741 19.096 130.992 19.416 129.328 20.056C127.664 20.696 125.915 21.6347 124.08 22.872L121.328 17.24C123.547 15.7894 125.808 14.7014 128.112 13.976C130.459 13.2507 132.912 12.888 135.472 12.888C140.251 12.888 143.963 14.0827 146.608 16.472C149.296 18.8614 150.64 22.2747 150.64 26.712V37.4C150.64 38.3387 150.789 39 151.088 39.384C151.387 39.7254 151.92 39.9387 152.688 40.024V47C151.92 47.1707 151.216 47.2774 150.576 47.32C149.979 47.4054 149.467 47.448 149.04 47.448C147.333 47.448 146.032 47.064 145.136 46.296C144.24 45.528 143.707 44.5894 143.536 43.48L143.344 41.752C141.851 43.672 140.016 45.144 137.84 46.168C135.707 47.1494 133.509 47.64 131.248 47.64C128.987 47.64 126.981 47.192 125.232 46.296C123.483 45.3574 122.096 44.0987 121.072 42.52C120.091 40.8987 119.6 39.0854 119.6 37.08ZM140.464 38.808C141.531 37.784 142.064 36.8027 142.064 35.864V32.472C139.845 31.576 137.605 31.128 135.344 31.128C133.083 31.128 131.248 31.6187 129.84 32.6C128.432 33.5387 127.728 34.7974 127.728 36.376C127.728 37.656 128.24 38.808 129.264 39.832C130.288 40.856 131.76 41.368 133.68 41.368C134.96 41.368 136.197 41.1334 137.392 40.664C138.629 40.1947 139.653 39.576 140.464 38.808Z"
								fill="#A6D0D9"
							/>
							<path
								d="M158.654 8.79203V0.280029H167.23V8.79203H158.654ZM158.654 47V13.464H167.23V47H158.654Z"
								fill="#A6D0D9"
							/>
							<path
								d="M174.902 0.280029H183.478V36.248C183.478 37.8267 183.734 38.872 184.246 39.384C184.758 39.896 185.462 40.152 186.358 40.152C187.041 40.152 187.745 40.0667 188.47 39.896C189.196 39.7254 189.836 39.512 190.39 39.256L191.542 45.784C190.39 46.3387 189.046 46.7654 187.51 47.064C185.974 47.3627 184.588 47.512 183.35 47.512C180.662 47.512 178.572 46.808 177.078 45.4C175.628 43.9494 174.902 41.9014 174.902 39.256V0.280029Z"
								fill="#A6D0D9"
							/>
							<path
								d="M195.215 0.280029H203.791V36.248C203.791 37.8267 204.047 38.872 204.559 39.384C205.071 39.896 205.775 40.152 206.671 40.152C207.354 40.152 208.058 40.0667 208.783 39.896C209.508 39.7254 210.148 39.512 210.703 39.256L211.855 45.784C210.703 46.3387 209.359 46.7654 207.823 47.064C206.287 47.3627 204.9 47.512 203.663 47.512C200.975 47.512 198.884 46.808 197.391 45.4C195.94 43.9494 195.215 41.9014 195.215 39.256V0.280029Z"
								fill="#A6D0D9"
							/>
							<path
								d="M231.079 47.64C227.41 47.64 224.253 46.8507 221.607 45.272C219.005 43.6507 216.999 41.5174 215.591 38.872C214.183 36.2267 213.479 33.368 213.479 30.296C213.479 27.1814 214.183 24.3014 215.591 21.656C217.042 19.0107 219.069 16.8987 221.671 15.32C224.317 13.6987 227.453 12.888 231.079 12.888C234.706 12.888 237.821 13.6987 240.423 15.32C243.069 16.8987 245.095 19.0107 246.503 21.656C247.911 24.3014 248.615 27.1814 248.615 30.296C248.615 33.368 247.911 36.2267 246.503 38.872C245.095 41.5174 243.069 43.6507 240.423 45.272C237.821 46.8507 234.706 47.64 231.079 47.64ZM222.311 30.296C222.311 32.2587 222.695 34.008 223.463 35.544C224.231 37.0374 225.277 38.2107 226.599 39.064C227.922 39.9174 229.415 40.344 231.079 40.344C232.701 40.344 234.173 39.9174 235.495 39.064C236.818 38.168 237.863 36.9734 238.631 35.48C239.442 33.944 239.847 32.1947 239.847 30.232C239.847 28.312 239.442 26.6054 238.631 25.112C237.863 23.576 236.818 22.3814 235.495 21.528C234.173 20.632 232.701 20.184 231.079 20.184C229.415 20.184 227.922 20.632 226.599 21.528C225.277 22.3814 224.231 23.576 223.463 25.112C222.695 26.6054 222.311 28.3334 222.311 30.296Z"
								fill="#A6D0D9"
							/>
							<path
								d="M285.572 47H276.996V28.184C276.996 25.496 276.527 23.5334 275.588 22.296C274.649 21.0587 273.348 20.44 271.684 20.44C270.532 20.44 269.359 20.7387 268.164 21.336C267.012 21.9334 265.967 22.7654 265.028 23.832C264.089 24.856 263.407 26.0507 262.98 27.416V47H254.404V13.464H262.148V19.672C263.385 17.5814 265.177 15.9387 267.524 14.744C269.871 13.5067 272.516 12.888 275.46 12.888C277.551 12.888 279.257 13.272 280.58 14.04C281.903 14.7654 282.927 15.768 283.652 17.048C284.377 18.2854 284.868 19.6934 285.124 21.272C285.423 22.8507 285.572 24.4507 285.572 26.072V47Z"
								fill="#A6D0D9"
							/>
							<path
								d="M305.381 47.64C302.522 47.64 299.792 47.1707 297.189 46.232C294.586 45.2934 292.346 43.9067 290.469 42.072L293.541 36.504C295.546 38.1254 297.509 39.32 299.429 40.088C301.349 40.856 303.226 41.24 305.061 41.24C306.725 41.24 308.048 40.9414 309.029 40.344C310.053 39.7467 310.565 38.8507 310.565 37.656C310.565 36.4187 309.946 35.5867 308.709 35.16C307.472 34.6907 305.744 34.1147 303.525 33.432C301.05 32.664 298.981 31.8747 297.317 31.064C295.653 30.2534 294.394 29.272 293.541 28.12C292.73 26.968 292.325 25.4747 292.325 23.64C292.325 20.3547 293.562 17.7307 296.037 15.768C298.512 13.8054 301.797 12.824 305.893 12.824C308.282 12.824 310.544 13.208 312.677 13.976C314.81 14.7014 316.752 15.896 318.501 17.56L315.045 23C311.76 20.44 308.581 19.16 305.509 19.16C304.144 19.16 302.928 19.416 301.861 19.928C300.837 20.44 300.325 21.336 300.325 22.616C300.325 23.8107 300.816 24.664 301.797 25.176C302.821 25.688 304.357 26.2427 306.405 26.84C309.05 27.6507 311.29 28.44 313.125 29.208C315.002 29.976 316.41 30.9574 317.349 32.152C318.33 33.3467 318.821 34.968 318.821 37.016C318.821 40.344 317.605 42.9467 315.173 44.824C312.741 46.7014 309.477 47.64 305.381 47.64Z"
								fill="#A6D0D9"
							/>
							<path
								d="M47.456 107.64C43.8293 107.64 40.6933 106.851 38.048 105.272C35.4453 103.693 33.4186 101.603 31.968 99C30.5173 96.3547 29.792 93.496 29.792 90.424C29.792 87.2667 30.496 84.3654 31.904 81.72C33.3546 79.032 35.4026 76.8987 38.048 75.32C40.6933 73.6987 43.8506 72.888 47.52 72.888C51.1893 72.888 54.3253 73.6987 56.928 75.32C59.5306 76.8987 61.5146 79.0107 62.88 81.656C64.288 84.2587 64.992 87.0747 64.992 90.104C64.992 91.2134 64.928 92.1734 64.8 92.984H38.88C39.0933 95.5867 40.0533 97.6347 41.76 99.128C43.5093 100.621 45.5146 101.368 47.776 101.368C49.4826 101.368 51.0826 100.963 52.576 100.152C54.112 99.2987 55.1573 98.1894 55.712 96.824L63.072 98.872C61.8346 101.432 59.8506 103.544 57.12 105.208C54.3893 106.829 51.168 107.64 47.456 107.64ZM38.688 87.544H56.224C55.968 85.0267 55.008 83 53.344 81.464C51.7226 79.8854 49.7386 79.096 47.392 79.096C45.088 79.096 43.104 79.8854 41.44 81.464C39.8186 83 38.9013 85.0267 38.688 87.544Z"
								fill="#A6D0D9"
							/>
							<path
								d="M101.384 107H92.8085V88.184C92.8085 85.496 92.3391 83.5334 91.4005 82.296C90.4618 81.0587 89.1605 80.44 87.4965 80.44C86.3445 80.44 85.1711 80.7387 83.9765 81.336C82.8245 81.9334 81.7791 82.7654 80.8405 83.832C79.9018 84.856 79.2191 86.0507 78.7925 87.416V107H70.2165V73.464H77.9605V79.672C79.1978 77.5814 80.9898 75.9387 83.3365 74.744C85.6831 73.5067 88.3285 72.888 91.2725 72.888C93.3631 72.888 95.0698 73.272 96.3925 74.04C97.7151 74.7654 98.7391 75.768 99.4645 77.048C100.19 78.2854 100.68 79.6934 100.936 81.272C101.235 82.8507 101.384 84.4507 101.384 86.072V107Z"
								fill="#A6D0D9"
							/>
							<path
								d="M121.193 107.64C118.335 107.64 115.604 107.171 113.001 106.232C110.399 105.293 108.159 103.907 106.281 102.072L109.353 96.504C111.359 98.1254 113.321 99.32 115.241 100.088C117.161 100.856 119.039 101.24 120.873 101.24C122.537 101.24 123.86 100.941 124.841 100.344C125.865 99.7467 126.377 98.8507 126.377 97.656C126.377 96.4187 125.759 95.5867 124.521 95.16C123.284 94.6907 121.556 94.1147 119.337 93.432C116.863 92.664 114.793 91.8747 113.129 91.064C111.465 90.2534 110.207 89.272 109.353 88.12C108.543 86.968 108.137 85.4747 108.137 83.64C108.137 80.3547 109.375 77.7307 111.849 75.768C114.324 73.8054 117.609 72.824 121.705 72.824C124.095 72.824 126.356 73.208 128.489 73.976C130.623 74.7014 132.564 75.896 134.313 77.56L130.857 83C127.572 80.44 124.393 79.16 121.321 79.16C119.956 79.16 118.74 79.416 117.673 79.928C116.649 80.44 116.137 81.336 116.137 82.616C116.137 83.8107 116.628 84.664 117.609 85.176C118.633 85.688 120.169 86.2427 122.217 86.84C124.863 87.6507 127.103 88.44 128.937 89.208C130.815 89.976 132.223 90.9574 133.161 92.152C134.143 93.3467 134.633 94.968 134.633 97.016C134.633 100.344 133.417 102.947 130.985 104.824C128.553 106.701 125.289 107.64 121.193 107.64Z"
								fill="#A6D0D9"
							/>
							<path
								d="M156.018 107.64C152.392 107.64 149.256 106.851 146.61 105.272C144.008 103.693 141.981 101.603 140.53 99C139.08 96.3547 138.354 93.496 138.354 90.424C138.354 87.2667 139.058 84.3654 140.466 81.72C141.917 79.032 143.965 76.8987 146.61 75.32C149.256 73.6987 152.413 72.888 156.082 72.888C159.752 72.888 162.888 73.6987 165.49 75.32C168.093 76.8987 170.077 79.0107 171.442 81.656C172.85 84.2587 173.554 87.0747 173.554 90.104C173.554 91.2134 173.49 92.1734 173.362 92.984H147.442C147.656 95.5867 148.616 97.6347 150.322 99.128C152.072 100.621 154.077 101.368 156.338 101.368C158.045 101.368 159.645 100.963 161.138 100.152C162.674 99.2987 163.72 98.1894 164.274 96.824L171.634 98.872C170.397 101.432 168.413 103.544 165.682 105.208C162.952 106.829 159.73 107.64 156.018 107.64ZM147.25 87.544H164.786C164.53 85.0267 163.57 83 161.906 81.464C160.285 79.8854 158.301 79.096 155.954 79.096C153.65 79.096 151.666 79.8854 150.002 81.464C148.381 83 147.464 85.0267 147.25 87.544Z"
								fill="#A6D0D9"
							/>
							<path
								d="M230.875 107H222.299V88.184C222.299 85.5387 221.83 83.5974 220.891 82.36C219.995 81.08 218.736 80.44 217.115 80.44C215.408 80.44 213.808 81.1014 212.315 82.424C210.822 83.704 209.755 85.3894 209.115 87.48V107H200.539V88.184C200.539 85.496 200.07 83.5334 199.131 82.296C198.235 81.0587 196.976 80.44 195.355 80.44C193.691 80.44 192.091 81.08 190.555 82.36C189.062 83.64 187.995 85.3254 187.355 87.416V107H178.779V73.464H186.523V79.672C187.76 77.496 189.446 75.832 191.579 74.68C193.755 73.4854 196.23 72.888 199.003 72.888C201.776 72.888 203.931 73.5707 205.467 74.936C207.046 76.3014 208.048 77.9867 208.475 79.992C209.798 77.688 211.526 75.9387 213.659 74.744C215.792 73.5067 218.182 72.888 220.827 72.888C222.918 72.888 224.603 73.272 225.883 74.04C227.206 74.7654 228.23 75.768 228.955 77.048C229.68 78.2854 230.171 79.6934 230.427 81.272C230.726 82.8507 230.875 84.4507 230.875 86.072V107Z"
								fill="#A6D0D9"
							/>
							<path
								d="M257.413 107.64C254.853 107.64 252.571 107.064 250.565 105.912C248.56 104.76 246.981 103.181 245.829 101.176V107H238.341V60.28H246.917V79.352C248.112 77.3467 249.669 75.768 251.589 74.616C253.552 73.464 255.835 72.888 258.437 72.888C261.339 72.888 263.877 73.6987 266.053 75.32C268.272 76.8987 270 79.0107 271.237 81.656C272.475 84.3014 273.093 87.2027 273.093 90.36C273.093 93.6027 272.411 96.5254 271.045 99.128C269.68 101.731 267.803 103.8 265.413 105.336C263.067 106.872 260.4 107.64 257.413 107.64ZM255.045 100.344C256.88 100.344 258.48 99.896 259.845 99C261.253 98.104 262.341 96.9094 263.109 95.416C263.92 93.88 264.325 92.216 264.325 90.424C264.325 88.5894 263.941 86.904 263.173 85.368C262.448 83.7894 261.424 82.5307 260.101 81.592C258.779 80.6534 257.264 80.184 255.557 80.184C253.68 80.184 251.952 80.824 250.373 82.104C248.837 83.3414 247.685 84.8774 246.917 86.712V94.712C247.472 96.376 248.56 97.7414 250.181 98.808C251.803 99.832 253.424 100.344 255.045 100.344Z"
								fill="#A6D0D9"
							/>
							<path
								d="M278.84 60.28H287.416V96.248C287.416 97.8267 287.672 98.872 288.184 99.384C288.696 99.896 289.4 100.152 290.296 100.152C290.979 100.152 291.683 100.067 292.408 99.896C293.133 99.7254 293.773 99.512 294.328 99.256L295.48 105.784C294.328 106.339 292.984 106.765 291.448 107.064C289.912 107.363 288.525 107.512 287.288 107.512C284.6 107.512 282.509 106.808 281.016 105.4C279.565 103.949 278.84 101.901 278.84 99.256V60.28Z"
								fill="#A6D0D9"
							/>
							<path
								d="M314.768 107.64C311.142 107.64 308.006 106.851 305.36 105.272C302.758 103.693 300.731 101.603 299.28 99C297.83 96.3547 297.104 93.496 297.104 90.424C297.104 87.2667 297.808 84.3654 299.216 81.72C300.667 79.032 302.715 76.8987 305.36 75.32C308.006 73.6987 311.163 72.888 314.832 72.888C318.502 72.888 321.638 73.6987 324.24 75.32C326.843 76.8987 328.827 79.0107 330.192 81.656C331.6 84.2587 332.304 87.0747 332.304 90.104C332.304 91.2134 332.24 92.1734 332.112 92.984H306.192C306.406 95.5867 307.366 97.6347 309.072 99.128C310.822 100.621 312.827 101.368 315.088 101.368C316.795 101.368 318.395 100.963 319.888 100.152C321.424 99.2987 322.47 98.1894 323.024 96.824L330.384 98.872C329.147 101.432 327.163 103.544 324.432 105.208C321.702 106.829 318.48 107.64 314.768 107.64ZM306 87.544H323.536C323.28 85.0267 322.32 83 320.656 81.464C319.035 79.8854 317.051 79.096 314.704 79.096C312.4 79.096 310.416 79.8854 308.752 81.464C307.131 83 306.214 85.0267 306 87.544Z"
								fill="#A6D0D9"
							/>
							<path
								d="M354.568 89.912V61.112H363.144V89.912H354.568ZM354.568 107V97.336H363.144V107H354.568Z"
								fill="#A6D0D9"
							/>
						</svg>
					</div>
					<ContactForm />
				</section>
			</div>
		</Layout>
	);
};

export default IndexPage;
